import axios from 'axios';

/* Storage */
import { getToken } from '../helpers/storage';

/* Utils */
import PathsBasic from '../utils/paths/pathsBasic';

axios.interceptors.request.use((config) => {
  const urlservice = config.url;
  const hasPath = PathsBasic.some(path => urlservice.includes(path));
  let token = getToken();
  if ( hasPath ){
    config.headers.Authorization = `Bearer ${process.env.REACT_APP_KEY_BASIC}`;
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
},
(error) => {
  return Promise.resolve(error.message);
});