const PathsBasic = [
    'home/plan',
    'home/teachers',
    'home/blog/comunity',
    'home/comment/register',
    'home/offers',
    'home/opinion-students',
    'profile/validate/password-old',
    'profile/search/email',
    'profile/search/user',
    'profile/student/recover-account',
    'profile/validate/link-recovery',
    'profile/activate-account/',
    'token/generate',
];

export default PathsBasic;