
/*****  *****/

const KEYS_STUDENT = [
    'names',
    'lastnames',
    'email',
    'plan',
    'payment'
];

export const storeStudent = (values) => {
    try {
      KEYS_STUDENT.forEach((key) => {
        if (values[key]) {
          localStorage.setItem(key, String(values[key]));
        }
      });
      return true;
    } catch (err) {
      return err;
    }
};

export const getStudent = () => {
  const studentData = {};
  KEYS_STUDENT.forEach((key) => {
    studentData[key] = localStorage.getItem(key); // Obteniendo los datos del localStorage
  });
  return studentData;
};

export const clearStudent = () => {
  KEYS_STUDENT.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const clearByStudent = (keys) => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

/*****  *****/

export const storePlan = (value) => {
    try {
        localStorage.setItem(`plankey`, String(value));
    } catch (err) {
      return err;
    }
};

export const getPlanKey = () => {
    let studentData = '';
    studentData = localStorage.getItem('plankey'); // Obteniendo los datos del localStorage
    return studentData;
};

export const clearPlanKey = () => {
    localStorage.removeItem('plankey');
};

/*****  *****/

/* For token */
export const storeToken = (value) => {
  try {
    localStorage.setItem(`token`, String(value));
  } catch (err) {
    return err;
  }
};

export const getToken = () => {
  let tokenData = '';
  tokenData = localStorage.getItem('token'); // Obteniendo los datos del localStorage
  return tokenData;
};

export const clearToken = () => {
  localStorage.removeItem('token');
};

/*****  *****/

/* For customerid */
const KEYS_CUSTOMER = [
  'customer',
  'studentid',
];

export const storeCustomer = (values) => {
  try {
    KEYS_CUSTOMER.forEach((key) => {
      if (values[key]) {
        localStorage.setItem(key, String(values[key]));
      }
    });
    return true;
  } catch (err) {
    return err;
  }
};

export const getCustomer = () => {
  const customerData = {};
  KEYS_CUSTOMER.forEach((key) => {
    customerData[key] = localStorage.getItem(key); // Obteniendo los datos del localStorage
  });
  return customerData;
};

export const clearCustomer = () => {
  KEYS_CUSTOMER.forEach((key) => {
    localStorage.removeItem(key);
  });
};

/*****  *****/

const KEYS_USER = [
  'key',
  'customer',
  'name',
  'lastname',
  'currency',
  'tour',
  'plan',
  'token',
  'word',
  'typeAccount'
];

export const storeUser = (values) => {
  try {
    KEYS_USER.forEach((key) => {
      if (values[key]) {
        localStorage.setItem(key, String(values[key]));
      }
    });
    return true;
  } catch (err) {
    return err;
  }
};

export const getUser = () => {
  const userData = {};
  KEYS_USER.forEach((key) => {
    userData[key] = localStorage.getItem(key); // Obteniendo los datos del localStorage
  });
  return userData;
};

export const clearUser = () => {
  KEYS_USER.forEach((key) => {
    localStorage.removeItem(key);
  });
};

/***  ****/
export const storeCupon = (values) => {
  try {
    localStorage.setItem('cupon', values);
  } catch (err) {
    return err;
  }
};

export const getCupon = () => {
  let cuponData = '';
  cuponData = localStorage.getItem('cupon'); // Obteniendo los datos del localStorage
  return cuponData;
};

export const clearCupon = () => {
  localStorage.removeItem('cupon');
};

/***  ****/
export const storeTeacher = (values) => {
  try {
    localStorage.setItem('teacher', values);
  } catch (err) {
    return err;
  }
};

export const getTeacher = () => {
  let teacherData = '';
  teacherData = localStorage.getItem('teacher');
  return teacherData;
};

export const clearTeacher = () => {
  localStorage.removeItem('teacher');
};