import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Paths from './routes/index.js';
import { ContextStore } from './store';
import '../src/helpers/axios.js';

const root = ReactDOM.createRoot(
  document.getElementById('root')  
);
// root.render(<ContextStore comp={browserRouter} />)
root.render(<ContextStore comp={<Paths />}/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
